import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

export type DesignGeneralSettingsParams = {
  showDivider: SettingsParamType.Boolean
}
export const designGeneralSettingsParams = createSettingsParams<DesignGeneralSettingsParams>({
  showDivider: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
})
