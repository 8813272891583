import {TimeSlot} from '@wix/ambassador-table-reservations-v1-time-slot/types'

import {LayoutBreakpointsConfig} from '../../components-shared/LayoutSizeProvider/types'

export const LAYOUT_BREAKPOINTS_START: LayoutBreakpointsConfig = {
  XS: 280,
  S: 490,
  M: 590,
  L: 1201,
}

export type ITimeSlotsByDays = {
  [index: string]: TimeSlot[]
}
