export const scrollTo = (dataHook: string) => {
  const element = document.querySelector(`[data-hook="${dataHook}"]`) as HTMLElement

  if (element) {
    // workaround after Chrome update
    setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }, 100)
  }
}
