import {StyleParamType, wixColorParam, wixFontParam, createStylesParams} from '@wix/tpa-settings'

export type LinkStylesParams = {
  linkFont: StyleParamType.Font
  linkColor: StyleParamType.Color
}

export const linkStylesParams = createStylesParams<LinkStylesParams>({
  linkFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
      style: {bold: false, italic: false, underline: true},
    }),
  },
  linkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
})
