import React from 'react'
import {BoxSelection, BoxSelectionBoxSize} from 'wix-ui-tpa/cssVars'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import isEqual from 'date-fns/isEqual'
import {Status, TimeSlot} from '@wix/ambassador-table-reservations-v1-time-slot/types'

import {getShortTime} from '../../../../utils/dateString'
import {LayoutSize} from '../../../../components-shared/LayoutSizeProvider/types'

import {tagsListDataHooks} from './constants'
import {classes, st} from './TagsList.st.css'

interface Props {
  label: string
  timeSlots: TimeSlot[]
  selectedTimeSlot?: Date
  regionalSettings?: string
  onSelectTimeSlot(item: TimeSlot): void
  extended?: boolean
  layoutSize: LayoutSize
}

export const TagsList: React.FC<Props> = ({
  layoutSize,
  label,
  timeSlots,
  selectedTimeSlot,
  onSelectTimeSlot,
  regionalSettings,
  extended = false,
}) => {
  if (!timeSlots?.length) {
    return null
  }

  const selectedTimeSlotIndex = selectedTimeSlot
    ? timeSlots.findIndex((item) => isEqual(selectedTimeSlot, item.startDate!))
    : -1

  return (
    <BoxSelection
      size={BoxSelectionBoxSize.small}
      name="time-slots"
      label={label}
      className={st(classes.boxSelection, {[layoutSize]: true, extended})}
      onChange={({id}) => {
        const timeSlot = timeSlots[Number(id)]
        const disabled = timeSlot.status !== Status.AVAILABLE

        if (!disabled) {
          onSelectTimeSlot(timeSlot)
        }
      }}
    >
      {timeSlots.map((timeSlot, index) => {
        const title = getShortTime(new Date(timeSlot.startDate!), regionalSettings)
        const disabled = timeSlot.status !== Status.AVAILABLE
        const selected = selectedTimeSlotIndex === index

        if (timeSlot.status === Status.NON_WORKING_HOURS) {
          return <div key={index} className={st(classes.nonWorkingHoursTimeSlot)} />
        }

        return (
          <BoxSelection.Option
            key={index}
            id={index.toString()}
            checked={selected}
            disabled={disabled}
            unavailable={disabled}
            aria-disabled={disabled}
            aria-label={title}
            data-hook={tagsListDataHooks.timeSlot({index, available: !disabled})}
          >
            <time className={st(classes.time, {selected})}>{title}</time>
          </BoxSelection.Option>
        )
      })}
    </BoxSelection>
  )
}
