import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

export type TextsSettingsParams = {
  titleText: SettingsParamType.Text
  titleRequestText: SettingsParamType.Text
  descriptionText: SettingsParamType.Text
  descriptionRequestText: SettingsParamType.Text
  buttonLabelText: SettingsParamType.Text
  buttonLabelRequestText: SettingsParamType.Text
}

export const textsSettingsParams = createSettingsParams<TextsSettingsParams>({
  titleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('uou-reservations.reservations.title'),
  },
  titleRequestText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('uou-reservations.reservations.title.request'),
  },
  descriptionText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('uou-reservations.reservations.subtitle'),
  },
  descriptionRequestText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('uou-reservations.reservations.subtitle.request'),
  },
  buttonLabelText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('uou-reservations.reservations.continue-cta'),
  },
  buttonLabelRequestText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('uou-reservations.reservations.continue-cta.request'),
  },
})
