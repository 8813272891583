import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Error} from '@wix/wix-ui-icons-common/on-stage'
import {SectionNotification} from 'wix-ui-tpa/cssVars'

import {classes} from './SelectTimeSlotError.st.css'

interface Props {}

export const SelectTimeSlotError: React.FC<Props> = () => {
  const {t} = useTranslation()

  return (
    <div className={classes.container}>
      <SectionNotification type="error">
        <SectionNotification.Icon icon={<Error />} />
        <SectionNotification.Text>
          {t('uou-reservations.reservations.select-time-slot-error')}
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  )
}
