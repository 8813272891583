import React, {Suspense, lazy} from 'react'
import {Divider, Text, TextButton, Button} from 'wix-ui-tpa/cssVars'
import {useSettings, useStyles} from '@wix/tpa-settings/react'
import {WidgetProps} from '@wix/yoshi-flow-editor'

import {withReservationsStorageProvider} from '../storage'
import {TimeSlotsList} from '../components/TimeSlotsList'
import {TimeSlotsListExtended} from '../components/TimeSlotsListExtended'
import {ReserveButton} from '../components/ReserveButton'
import {SearchAgainButton} from '../components/SearchAgainButton'
import {Form} from '../components/Form'
import {settingsParams} from '../settingsParams'
import {stylesParams} from '../stylesParams'
import {ButtonAlignment, TextAlignment} from '../../../types/createStylesParams'
import {openBmLocationSettingsPage} from '../../../utils/navigation'
import {RequestStatus} from '../../../utils/wrapRequest'
import {RtlProvider} from '../../../components-shared/RtlProvider'
import {withLayoutSizeProvider} from '../../../components-shared/LayoutSizeProvider'
import {LAYOUT_BREAKPOINTS_START} from '../constants'
import {NotAcceptingBanner} from '../../../components-shared/NotAcceptingBanner'

const NotAcceptingModal = lazy(
  () => import(/* webpackChunkName: "NotAcceptingModal" */ '../components/NotAcceptingModal'),
)

import {useHooks} from './useHooks'
import {classes, st} from './Widget.st.css'
import {reservationsDataHooks, ReservationsPageType} from './constants'

const Widget: React.FC<WidgetProps> = () => {
  const {
    t,
    submitButtonContentWidth,
    layoutSize,
    containerWidth,
    regionalSettings,
    isInitialDataInProgress,
    getTimeSlotsStatus,
    handleReservationDataStatus,
    reservationsPageType,
    partySizeOptions,
    timeOptions,
    partySize,
    selectedDate,
    selectedTime,
    selectedTimeSlot,
    timeSlots,
    handlePartySizeChange,
    handleDateChange,
    handleTimeChange,
    handleTimeSlotChange,
    filterDate,
    handleSubmit,
    isLargeParty,
    phoneNumber,
    reservationLocationsOptions,
    selectedReservationLocation,
    handleLocationChange,
    shouldShowNoLocationsEditorWarning,
    isPreview,
    metaSiteId,
    showSelectTimeSlotError,
    handleSearchAgainClick,
    isQueryValid,
    handleErrorClick,
    shouldShowSearchAgainButton,
    shouldShowReservationsUnavailable,
    handleHideReservationsUnavailable,
    isNoReservationLocations,
    shouldShowAutoApproveText,
    showNoAvailableTimeSlotNotification,
  } = useHooks()
  const settings = useSettings()
  const styles = useStyles()

  const isLoading = handleReservationDataStatus === RequestStatus.LOADING
  const handleReservationDataRequestFailed = handleReservationDataStatus === RequestStatus.FAILED
  const getTimeSlotsRequestFailed = getTimeSlotsStatus === RequestStatus.FAILED

  const showTitle = settings.get(settingsParams.showTitle)
  const showDescription = settings.get(settingsParams.showDescription)
  const textAlignment = settings.get(settingsParams.textAlignment)
  const showDivider = settings.get(settingsParams.showDivider)

  const textAlignmentClass = TextAlignmentToClass[textAlignment]

  const titleText = settings.get(
    shouldShowAutoApproveText ? settingsParams.titleText : settingsParams.titleRequestText,
  )
  const titleTag =
    styles.get(stylesParams.generalTitleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.generalTitleFont).htmlTag

  const descriptionText = settings.get(
    shouldShowAutoApproveText
      ? settingsParams.descriptionText
      : settingsParams.descriptionRequestText,
  )

  const subtitleTag =
    styles.get(stylesParams.generalSubtitleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.generalSubtitleFont).htmlTag

  const buttonLabelText = settings.get(
    shouldShowAutoApproveText
      ? settingsParams.buttonLabelText
      : settingsParams.buttonLabelRequestText,
  )

  const buttonAlignment = settings.get(settingsParams.buttonAlignment)
  const buttonAlignmentClass = ButtonAlignmentToClass[buttonAlignment]

  if (isNoReservationLocations || handleReservationDataRequestFailed) {
    return (
      <RtlProvider>
        <div className={st(classes.root, {[layoutSize]: true})}>
          <NotAcceptingBanner layoutSize={layoutSize} className={classes.notAcceptingBanner} />
        </div>
      </RtlProvider>
    )
  }

  return (
    <RtlProvider>
      <div
        className={st(classes.root, {[layoutSize]: true})}
        data-hook={reservationsDataHooks.root()}
      >
        <div className={classes.contentWrapper}>
          <form className={classes.formRoot} onSubmit={handleSubmit}>
            {shouldShowNoLocationsEditorWarning && (
              <div className={classes.noLocationsEditorWarning}>
                <Text className={st(classes.bodyText)}>
                  {t('uou-reservations.reservations.no-location-editor-title')}
                </Text>
                {isPreview && metaSiteId ? (
                  <Button onClick={() => openBmLocationSettingsPage(metaSiteId)}>
                    {t('uou-reservations.reservations.no-location-editor-cta')}
                  </Button>
                ) : null}
              </div>
            )}

            {getTimeSlotsRequestFailed || !isQueryValid ? (
              <div className={classes.header}>
                <TextButton
                  role="alert"
                  onClick={handleErrorClick}
                  className={st(classes.refreshPageError)}
                >
                  {t('uou-reservations.reservations.refresh-page-error')}
                </TextButton>
              </div>
            ) : null}

            {showTitle || showDescription ? (
              <div className={classes.titleAndSubtitleContainer}>
                {showTitle && (
                  <Text
                    tagName={titleTag}
                    className={st(
                      classes.title,
                      textAlignmentClass,
                      showDescription ? classes.spaceBetweenTitleAndDescription : undefined,
                    )}
                  >
                    {titleText}
                  </Text>
                )}

                {showDescription && (
                  <Text tagName={subtitleTag} className={st(classes.subtitle, textAlignmentClass)}>
                    {descriptionText}
                  </Text>
                )}
              </div>
            ) : null}

            <div>
              <Form
                layoutSize={layoutSize}
                containerWidth={containerWidth}
                regionalSettings={regionalSettings}
                location={{
                  value: selectedReservationLocation?.id ?? undefined,
                  options: reservationLocationsOptions,
                  onChange: handleLocationChange,
                }}
                partySize={{
                  value: partySize.toString(),
                  options: partySizeOptions,
                  onChange: handlePartySizeChange,
                }}
                date={{
                  value: selectedDate,
                  filterDate,
                  onChange: handleDateChange,
                }}
                time={{
                  value: selectedTime?.id,
                  options: timeOptions,
                  onChange: handleTimeChange,
                }}
              />

              {isLargeParty ? (
                <div className={classes.footer}>
                  <Text className={classes.bodyText}>
                    {t('uou-reservations.reservations.larger-party', {phoneNumber})}
                  </Text>
                </div>
              ) : (
                <>
                  {reservationsPageType === ReservationsPageType.DEFAULT ? (
                    <>
                      <div className={classes.dividerContainer}>
                        {showDivider && <Divider className={classes.divider} />}
                      </div>
                      <TimeSlotsList
                        layoutSize={layoutSize}
                        selectedDate={selectedDate}
                        selectedTimeOption={selectedTime}
                        partySize={partySize}
                        regionalSettings={regionalSettings}
                        items={timeSlots?.[Object.keys(timeSlots)?.[0]]}
                        selectedTimeSlot={selectedTimeSlot}
                        showSelectTimeSlotError={showSelectTimeSlotError}
                        showNoAvailableTimeSlotNotification={showNoAvailableTimeSlotNotification}
                        onSelectTimeSlot={handleTimeSlotChange}
                      />
                    </>
                  ) : null}

                  {reservationsPageType === ReservationsPageType.EXTENDED ? (
                    <TimeSlotsListExtended
                      layoutSize={layoutSize}
                      regionalSettings={regionalSettings}
                      items={timeSlots}
                      partySize={partySize}
                      selectedDate={selectedDate}
                      selectedTimeSlot={selectedTimeSlot}
                      showSelectTimeSlotError={showSelectTimeSlotError}
                      onSelectTimeSlot={handleTimeSlotChange}
                    />
                  ) : null}

                  <div className={classes.footer}>
                    {reservationsPageType === ReservationsPageType.EMPTY &&
                    !isInitialDataInProgress ? (
                      <Text className={classes.bodyText} role="alert">
                        {t('uou-reservations.reservations.time-slot-unavailable-date')}
                      </Text>
                    ) : (
                      <div className={st(classes.reserveButtonWrapper, buttonAlignmentClass)}>
                        <ReserveButton
                          isLoading={isLoading || isInitialDataInProgress}
                          width={submitButtonContentWidth}
                          label={buttonLabelText}
                        />
                      </div>
                    )}

                    {shouldShowSearchAgainButton ? (
                      <div className={st(classes.searchAgainButtonWrapper, buttonAlignmentClass)}>
                        <SearchAgainButton
                          isLoading={isLoading}
                          disabled={isInitialDataInProgress}
                          onClick={handleSearchAgainClick}
                        />
                      </div>
                    ) : null}
                  </div>
                </>
              )}
            </div>
          </form>
          <Suspense fallback={<div />}>
            <NotAcceptingModal
              isOpen={shouldShowReservationsUnavailable}
              onClose={handleHideReservationsUnavailable}
              layoutSize={layoutSize}
              bannerClassName={classes.notAcceptingBanner}
            />
          </Suspense>
        </div>
      </div>
    </RtlProvider>
  )
}

const TextAlignmentToClass: Record<TextAlignment, string> = {
  [TextAlignment.Left]: classes.left,
  [TextAlignment.Center]: classes.center,
  [TextAlignment.Right]: classes.right,
}

const ButtonAlignmentToClass: Record<ButtonAlignment, string> = {
  [ButtonAlignment.Left]: classes.alignStart,
  [ButtonAlignment.Center]: classes.alignCenter,
  [ButtonAlignment.Right]: classes.alignEnd,
  [ButtonAlignment.Stretch]: classes.alignInitial,
}

export default withLayoutSizeProvider(
  withReservationsStorageProvider(Widget),
  LAYOUT_BREAKPOINTS_START,
)
