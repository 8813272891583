import React, {FC} from 'react'
import {Button} from 'wix-ui-tpa/cssVars'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'

import {classes, st} from './SearchAgainButton.st.css'
import {searchAgainButtonDataHooks} from './constants'

interface Props {
  disabled: boolean
  isLoading: boolean
  onClick: () => void
}

export const SearchAgainButton: FC<Props> = ({disabled, isLoading, onClick}) => {
  const {t} = useTranslation()

  return (
    <Button
      disabled={isLoading || disabled}
      type="button"
      className={st(classes.button)}
      data-hook={searchAgainButtonDataHooks.button()}
      onClick={onClick}
    >
      {t('uou-reservations.reservations.search-again-cta')}
    </Button>
  )
}
