import {StyleParamType, wixColorParam, wixFontParam, createStylesParams} from '@wix/tpa-settings'

export type TimeSlotsDefaultStylesParams = {
  timeSlotsDefaultFont: StyleParamType.Font
  timeSlotsDefaultColor: StyleParamType.Color
  timeSlotsDefaultFillOpacityColor: StyleParamType.Color
  timeSlotsDefaultBorderOpacityColor: StyleParamType.Color
  timeSlotsDefaultBorderWidth: StyleParamType.Number
  timeSlotsDefaultCornerRadius: StyleParamType.Number
}

export const timeSlotsDefaultStylesParams = createStylesParams<TimeSlotsDefaultStylesParams>({
  timeSlotsDefaultFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: (params) =>
      wixFontParam('Body-M', {
        size: params.isMobile ? 14 : 16,
      })(params),
  },
  timeSlotsDefaultColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  timeSlotsDefaultFillOpacityColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  timeSlotsDefaultBorderOpacityColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
  timeSlotsDefaultBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  timeSlotsDefaultCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
})

export type TimeSlotsSelectedStylesParams = {
  timeSlotsSelectedTextColor: StyleParamType.Color
  timeSlotsSelectedFillOpacityColor: StyleParamType.Color
  timeSlotsSelectedBorderOpacityColor: StyleParamType.Color
  timeSlotsSelectedBorderWidth: StyleParamType.Number
  timeSlotsSelectedCornerRadius: StyleParamType.Number
}

export const timeSlotsSelectedStylesParams = createStylesParams<TimeSlotsSelectedStylesParams>({
  timeSlotsSelectedTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  timeSlotsSelectedFillOpacityColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.1),
  },
  timeSlotsSelectedBorderOpacityColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  timeSlotsSelectedBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  timeSlotsSelectedCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
})

export type TimeSlotsClosedStylesParams = {
  timeSlotsClosedFillOpacityColor: StyleParamType.Color
  timeSlotsClosedCornerRadius: StyleParamType.Number
}

export const timeSlotsClosedStylesParams = createStylesParams<TimeSlotsClosedStylesParams>({
  timeSlotsClosedFillOpacityColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.1),
  },
  timeSlotsClosedCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
})
