import React, {useEffect} from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {TimeSlot, Status} from '@wix/ambassador-table-reservations-v1-time-slot/types'

import {TagsList} from '../TagsList'
import {TimeOption} from '../../../../utils/timeOptions'
import {SelectTimeSlotError} from '../SelectTimeSlotError'
import {LayoutSize} from '../../../../components-shared/LayoutSizeProvider/types'
import {NotAvailableTimeSlotNotification} from '../NotAvailableTimeSlotNotification'

import {timeSlotsListDataHooks} from './constants'

interface Props {
  regionalSettings?: string
  selectedTimeOption?: TimeOption
  items: TimeSlot[]
  selectedTimeSlot?: Date
  selectedDate?: Date
  partySize: number
  showNoAvailableTimeSlotNotification: boolean
  showSelectTimeSlotError: boolean
  onSelectTimeSlot: (item: TimeSlot) => void
  layoutSize: LayoutSize
}

export const TimeSlotsList: React.FC<Props> = ({
  layoutSize,
  regionalSettings,
  selectedTimeOption,
  items = [],
  selectedTimeSlot,
  selectedDate,
  partySize,
  showNoAvailableTimeSlotNotification,
  showSelectTimeSlotError,
  onSelectTimeSlot,
}) => {
  const {t} = useTranslation()

  useEffect(() => {
    const preferredTime = items.find(
      (item) =>
        selectedTimeOption?.data.hours === item.startDate?.getHours() &&
        selectedTimeOption?.data.minutes === item.startDate?.getMinutes(),
    )

    if (preferredTime && preferredTime.status === Status.AVAILABLE) {
      onSelectTimeSlot(preferredTime)
    }
  }, [items])

  if (!items?.length) {
    return null
  }

  return (
    <div data-hook={timeSlotsListDataHooks.root()}>
      {showSelectTimeSlotError && <SelectTimeSlotError />}
      {showNoAvailableTimeSlotNotification ? (
        <NotAvailableTimeSlotNotification
          date={selectedDate!}
          partySize={partySize}
          regionalSettings={regionalSettings}
        />
      ) : null}

      <TagsList
        layoutSize={layoutSize}
        label={t('uou-reservations.reservations.time-slots-text')}
        timeSlots={items}
        regionalSettings={regionalSettings}
        onSelectTimeSlot={onSelectTimeSlot}
        selectedTimeSlot={selectedTimeSlot}
      />
    </div>
  )
}
