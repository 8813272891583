import {StyleParamType, createStylesParams} from '@wix/tpa-settings'

export type LayoutStylesParams = {
  spaceBetweenTitleAndDescription: StyleParamType.Number
  spaceBetweenTextAndForm: StyleParamType.Number
  spaceBetweenFormAndTimeSlots: StyleParamType.Number
  sidePadding: StyleParamType.Number
  topBottomPadding: StyleParamType.Number
  buttonPadding: StyleParamType.Number
}

export const layoutStylesParams = createStylesParams<LayoutStylesParams>({
  spaceBetweenTitleAndDescription: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: () => 20,
  },
  spaceBetweenTextAndForm: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: (params) => (params.isMobile ? 40 : 80),
  },
  spaceBetweenFormAndTimeSlots: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: (params) => (params.isMobile ? 40 : 80),
  },
  sidePadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: (params) => (params.isMobile ? 20 : 40),
  },
  topBottomPadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: (params) => (params.isMobile ? 20 : 40),
  },
  buttonPadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: () => 20,
  },
})
